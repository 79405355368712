<template>
    <div :class="{fullNav: (!this.$route.params.client), partialNav:(this.$route.params.client)}" class="ClientStoriesNavigation" id="ClientStoriesNavigation">
        <header class="ClientStoriesNavigation-content" v-if="this.$route.params.client">
            <h3 class="title ClientStoriesNavigation-title">{{this.title}}</h3>
        </header>
        <nav class="ClientStoriesNavigation-nav">
            <ClientStoriesThumbnail
                    :client="clientStory"
                    :key="clientStory.index"
                    v-for="clientStory in this.clientStories"
            />
        </nav>
    </div>
</template>

<script>
    import store from '@/store';

    export default {
        name: 'ClientStoriesNavigation',
        data() {
            return {
                sceneTimer: null,
                publicPath: process.env.BASE_URL,
                ourClients: store.state.ourClients,
                title: (this.$route.params.client) ? 'Other clients stories' : 'Client stories'
            };
        },
        components: {
            'ClientStoriesThumbnail': () => import('@/components/ClientStoriesThumbnail')
        },
        computed: {
            clientStories() {
                return this.ourClients.filter(client => client.story).filter(client => client.key !== this.$route.params.client).sort(function (a, b) {return a.story.order - b.story.order;});
            }
        }
    };
</script>
